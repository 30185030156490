import React, { FC, useState } from "react";
import { icons } from "src/assets";
import arrow from '../../assets/icons/d-arr.svg';


const LangModal = ({setShowLangModal, currentValue, options, languageCallback}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    // const [language, setLanguage] = useState<string>('English');

    const handleLanguages = (lang:{value: string, display: string}) => {
        // setLanguage(lang);
        setIsOpened(false);
        languageCallback(lang)
    }

    return(
        <div className="lang-modal-cont">
            <div className="blur-bg"/>
            <div className="lang-modal">
                <h1>Aether TCG{'\n'}service status</h1>
                <p>Please select your language</p>
                <div className="select-menu">
                    <input type={"checkbox"} onChange={(e) => setIsOpened(e.target.checked)} id="select-menu" checked={isOpened}/>
                    <label htmlFor="select-menu"><span>{currentValue.display}</span> <img src={arrow} alt="arrow"/></label>
                    {isOpened && 
                    <ul>
                        {Object.values(options).map((l: any) => <li key={l.value} onClick={() => handleLanguages(l.value)}>{l.display}</li>)}
                    </ul>
                    }
                </div>
                <button onClick={() => setShowLangModal(false)}>
                    <span>
                 Continue

                    </span>
                </button>
            </div>
        </div>
    )
}

export default LangModal;
import React, { useState } from "react";
import { icons } from "src/assets";
import check from '../../assets/icons/check.svg';
const ReportModal = ({setShowReportModal}) => {
    const [success, setSuccess] = useState(false);
    const [agreeToPolicy, setAgreeToPolicy] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    const submitForm = (e: any) => {
        e.preventDefault();

        fetch(process.env.REACT_APP_API_BASE_URL + 'api/send_report/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "text": text,
                "name": name,
                "email": email,
            })
        }).then(resp => resp.json()).then(resp => {
            setSuccess(true);
        })
    }

    const handleClose = () => {
        setShowReportModal(false)
        setName("");
        setEmail("");
        setText("");
    }

    return (
        <div className="lang-modal-cont report">
            <div className="blur-bg" />
            <div className="lang-modal">
                <img src={icons.close} alt="close" className="close-icon" onClick={() => handleClose()}/>
                { !success && <>
                    <h1>Report</h1>
                    <p>Please apply your message and fill contact info </p>
                    <form className="report-cont" onSubmit={submitForm}>
                        <input type={"text"} placeholder="Name" required defaultValue={name} onChange={(e) => setName(e.target.value)}/>
                        <input type={"email"} placeholder="E-mail" required defaultValue={email} onChange={(e) => setEmail(e.target.value)}/>
                        <textarea
                            defaultValue={text} onChange={(e) => setText(e.target.value)}
                            placeholder="Crypto transfers are currently unavailable."
                            required
                        />
                        <section>
                            <label htmlFor="privacy-policy">
                                <span>
                                    {agreeToPolicy && <img src={check} alt="check" />}
                                    </span>
                                <input type={"checkbox"} id="privacy-policy" onChange={() => setAgreeToPolicy(!agreeToPolicy)} checked={agreeToPolicy}/> 
                                I agree to the
                                <a href="https://www.aethergames.io/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy</a>
                            </label>
                            <button type="submit" disabled={!agreeToPolicy || !name || !email || !text}><span>Apply</span></button>
                        </section>
                    </form>
                </>}
                {success && <div id="report-success">
                    <h1>Success!</h1>
                    <section>
                        <button onClick={() => handleClose()}><span>Close</span></button>
                    </section>
                </div>}
            </div>
        </div>
    )
}

export default ReportModal;
import { InjectedConnector } from "@web3-react/injected-connector";
import { backgrounds, icons } from "src/assets";

export const ethereumConnector = new InjectedConnector({
  supportedChainIds:[0x1,0x5],
});


export const HEADER = [
  {
    title: "Homepage",
    linkto: "/",
  },
  // {
  //   title: "Lore",
  //   linkto: "/lore",
  // },
  // {
  //   title: "Mint",
  //   linkto: "/mint",
  // },
  {
    title: "How To Play",
    linkto: "/how-to-play",
  },
  // {
  //   title: "Blog",
  //   linkto: "/blog",
  // },
  {
    title: "Service Status",
    linkto: "/support",
  },
  // {
  //   title: "Tokenomics",
  //   linkto: "/tokenomics",
  // },
];

export const SOCIALS = [
  {
    icon: icons.dsSvg,
    hover: icons.dsHoverSvg,
    linkto: "https://discord.gg/CVYens9FGJ",
  },
  {
    icon: icons.instSvg,
    hover: icons.instHoverSvg,
    linkto: "https://www.instagram.com/aethergamesinc/",
  },
  {
    icon: icons.ytbSvg,
    hover: icons.ytbHoverSvg,
    linkto: "https://www.youtube.com/channel/UCaYVucgHw9ZmYwGoxH1izgQ",
  },
  {
    icon: icons.twitterSvg,
    hover: icons.twitterHoverSvg,
    linkto: "https://x.com/AetherGamesInc",
  },
  {
    icon: icons.telegramSvg,
    hover: icons.telegramHoverSvg,
    linkto: "https://t.me/aethergames",
  },
];

export const CHARACTERS = [
  {
    name: "Dragons",
    desc: "In our world, dragons are similar to classical fantasy representations in both size and ability. Full grown dragons can achieve heights of 20 meters plus, putting even the mighty Dragon from Game of Thrones to shame.",
    img: backgrounds.buttons.dragons,
    hover: backgrounds.buttons.dragonsHover,
  },
  {
    name: "Trolls",
    desc: "Trolls are a well developed and intelligent race. But in contrast to demons, they decided to follow the way of nature, rather than magic and mystic knowledge.",
    img: backgrounds.buttons.trolls,
    hover: backgrounds.buttons.trollsHover,
  },
  {
    name: "Fairies",
    desc: "Fairies originally were humans, but were corrupted when they touched sacred magic from another world. This magic mutated the humans into the insect like creatures we see today.",
    img: backgrounds.buttons.fairies,
    hover: backgrounds.buttons.fairiesHover,
  },
  {
    name: "Beasts",
    desc: "Beasts are creatures from both worlds and can differ greatly in size and shape, the same way a lynx is vastly different from an elephant.",
    img: backgrounds.buttons.beasts,
    hover: backgrounds.buttons.beastsHover,
  },
  {
    name: "Demons",
    desc: "Demons are anthropomorphic creatures who’s skin color is determined by their corresponding element. While they may retain some animal features, such as horns, wings and tails, they are distinguished by their hairless coarse skin and gruesome facial features.",
    img: backgrounds.buttons.demons,
    hover: backgrounds.buttons.demonsHover,
  },
  {
    name: "Elementals",
    desc: "Elementals are spirits of the elements. It is the only race that does not have any corporality. Elementals can use any shape and size they want, but will always be distinguishable based on their current element. It is important to note that although they are an element’s incarnation, they don’t actually belong to a specific element.",
    img: backgrounds.buttons.elementals,
    hover: backgrounds.buttons.elementalsHover,
  },
];

export const GAMEPLAY = [
  // {
  //   title: "Blockchain-based game",
  //   icon: icons.based,
  // },
  {
    title: "150 collectible cards",
    icon: icons.aeg_cards,
  },
  {
    title: "$AEG Token - in-game currency.",
    icon: icons.aeg,
  },
  // {
  //   title: "$AEG Token - in-game currency. ",
  //   icon: icons.nft,
  // },
];

export const TOKENOMICS = [
  {
    img: backgrounds.buttons.rewards,
    hover: backgrounds.buttons.rewardsHover,
    amount: "270,000,000",
    reveal:
      "Locked, released for Staking: liquidity providers, NFT holders in game; PVP&PVE",
    percent: 27,
  },
  {
    img: backgrounds.buttons.treasury,
    hover: backgrounds.buttons.treasuryHover,
    amount: "100,000,000",
    reveal: "1 year cliff, then 48 months linear vesting",
    percent: 10,
  },
  {
    img: backgrounds.buttons.team,
    hover: backgrounds.buttons.teamHover,
    amount: "100,000,000",
    reveal: "2 years cliff, then 60 months linear vesting",
    percent: 10,
  },
  {
    img: backgrounds.buttons.marketing,
    hover: backgrounds.buttons.marketingHover,
    amount: "60,000,000",
    reveal: "2% TGE, 6 months cliff, then 36 months linear vesting",
    percent: 6,
  },
  {
    img: backgrounds.buttons.advisors,
    hover: backgrounds.buttons.advisorsHover,
    amount: "40,000,000",
    reveal: "1 year cliff, then 48 months linear vesting",
    percent: 4,
  },
  {
    img: backgrounds.buttons.balancer,
    hover: backgrounds.buttons.balancerHover,
    amount: "125,000,000",
    reveal: "Uncirculated till needed",
    percent: 12.5,
  },
  {
    img: backgrounds.buttons.seed,
    hover: backgrounds.buttons.seedHover,
    amount: "150,000,000",
    reveal: "0% TGE, cliff 12 months, and then linear release for 30 months",
    percent: 15,
  },
  {
    img: backgrounds.buttons.strategic,
    hover: backgrounds.buttons.strategicHover,
    amount: "125,000,000",
    reveal: "5% at TGE, cliff 10 months, and then linear release for 20 months",
    percent: 12.5,
  },
  {
    img: backgrounds.buttons.presale,
    hover: backgrounds.buttons.presaleHover,
    amount: "20,000,000",
    reveal: "25% at TGE, and then linear release for 5 months",
    percent: 2,
  },
  {
    img: backgrounds.buttons.initial,
    hover: backgrounds.buttons.initialHover,
    amount: "10,000,000",
    reveal: "No vesting",
    percent: 1,
  },
];

export const COMMUNITIES = [
  {
    icons: icons.youtube,
    title: "YouTube",
    linkto: "https://www.youtube.com/channel/UCaYVucgHw9ZmYwGoxH1izgQ",
  },
  {
    icons: icons.telegram,
    title: "Telegram",
    linkto: "https://t.me/aethergames",
  },
  {
    icons: icons.discord,
    title: "Discord",
    linkto: "https://discord.com/invite/AetherGames",
  },
  {
    icons: icons.twitter,
    title: "Twitter",
    linkto: "https://x.com/AetherTCG",
  },
  {
    icons: icons.instagram,
    title: "Instagram",
    linkto: "https://www.instagram.com/aethergamesinc/",
  },
];

export const AGES = {
  firstAge: {
    "YEAR 0":
      "The three Gods, immaterial and eternal, swim together through the Void. They move as one being, immutable and complete. Much time passes like this, and eventually they begin to experiment. They pull together the pieces of themselves, matter, space, and time, with meticulous abandon. They form stars, comets, galaxies and so the universe is shaped bit by bit. Finally, a planet, their favorite, emerges through the settling dust of creation. This planet intrigues them, and they decide, as eternal and whole as they are, to test their work. They cannot, however, experience it in their condition. After much contemplation they choose three separate forms: the first is Melkree, the ruler of matter, who takes the shape of a Troll to forge a deep connection with the earth. The second is Meoldrorth, ruler of space, who manifests as a Dragon and claims the freedom to travel long distances with ease. The third and final form is Vargad, the insistent and discreet ruler of time, presents as a Demon, and presides over mystery and all that lurks within the shadows.",
    "YEAR 200":
      "The three Gods spend many years adjusting to their new, separate bodies and experiencing their new, stimulating world. Two centuries pass and though they grow fond of the planet, loneliness weighs heavily upon each of them. They decide, after much thought, to create once again, this time modeling beings off their newfound forms. This, in short, is how Trolls, Dragons, and Demons came to be and for many years they live in peace, sharing the knowledge and resources of their world.",
    "YEAR 1347":
      "The prosperity once shared between the families has greatly deteriorated. The world has been divided into three vast territories over which many wars are raged. The Gods, greatly disappointed in the fickleness of their creations, retire their interest and forge a realm in which they may once again live together, leaving their children to fight over their planet.",
    "YEAR 1417":
      "Gredyrth, ruler of the Dragons and mad with greed, attacks the celestial fortress of the Gods. Her hunger for their power of creation drives her to their doors where, outnumbered and unprepared, they create Nayid, a powerful combination of the Gods’ desperation in the moment. The ferocious creature repels Gredyrth’s rebel Dragon armies with fearful ease. As thanks, the Gods create more creatures like Nayid, gifting them land that once belonged to the Dragons as penance for their avarice. This is how the family of Beasts came to be. In the years that follow, Meoldrorth, expressing great disappointment in their children, puts an end to Gredyrth’s dictatorial reign and establishes the Seven Kingdoms of Tergaroth in the Dragon’s territory. This becomes a well-known place of intellect and diplomacy, where decisions are made collectively.",
    "YEAR 2111":
      "The families continue to feud, and through this an alliance between Beasts and Trolls is formed. Together, they attack the Dragons with merciless fervor and after many battles, the Dragons’ strength begins to weaken. Many times, in desperation they call upon the Demons for aide, only to be greeted with silence.",
    "YEAR 2119":
      "The wars rage on and the Pillars of Tergaroth fall to the Beast and Troll armies. As a last resort, the Seven Dragon Matriarchs call to their deity and implore them for help. Meoldrorth in turn asks the others, Melkree and Vargad, to join forces and bring peace to the land and their people. But the two Gods are jaded and have little desire to interfere in the affairs of the world, refusing the call to arms from the comfort of their celestial palace. Meoldrorth, in a fit of rage, pours out all their destructive magic onto the armies of Trolls and Beasts that decimating their people. Melkree, closest to Meoldrorth, manages to subdue them after three days of disaster. This magic, released into the world by Meoldrorth, forms hot spots from which emerge the first Elemental beings.",
  },
  secondAge: {
    "YEAR 0":
      "The world recovers from Meoldrorth’s scorn. The Dragons, now far fewer than before, slowly attempt to restore their people. The newly formed Elementals wander the world, unorganized, and landless. It is still considered great luck in many cultures to happen across one.",
    "YEAR 553":
      "The Dragons struggle to rebuild their meager numbers and Meoldrorth broods over past events. They spend years on tests, studying the extent of their power. One night, Meoldrorth begins one last experiment. They surreptitiously create a portal, joining their world with our Earth, which they promise as a gift to the Dragons, a new beginning. Through the portal they discover the new creatures to be small and weak, and so Meoldrorth in one wave of their hand, orders the extinction of humankind.",
    "YEAR 556 / YEAR 956 CE":
      "In the Viking era of Earth, Dragons emerge from a mystical portal in the mountains of Scandinavia and attack. Humans are overwhelmed by the magic and ferocity of such otherworldly creatures.",
    "YEAR 559 / YEAR 959 CE":
      "Vargad, for the first time in ages, emerges from the shadows to inform Melkree of Meoldrorth’s actions. Tensions are rekindled between the families as the news breaks, each sending monster scouts through the portal, some to carve out a piece of land for themselves and others to protect the vulnerable humans from total annihilation. Clans form within the different families, all clamoring for a different cause.",
    "YEAR 566 / YEAR 966 CE":
      "Humans cross over into the realm of the monsters and lose themselves; the magic of the strange world slowly corrupts their bodies and minds. When subjected to this treatment for too long they cease to be human. This is how Faeries were introduced into their world, beings comprised of both human and magical parts, insect-like and mischievous.",
    // "6":"Having seen enough of monsters slaughtering humans and fighting a heedless battle over Earth, Vargad sends the Demon of Light Tar’ilath to restore balance. The Demon brings knowledge of Runes to the humans. Patiently, Tar’ilath teaches them how to manipulate magic in a way that does not corrupt them. The humans accept this kindness readily, finally able to defend themselves against the onslaught of magic and destruction. They enter a period of near autonomy, needing less and less help from the few creatures that used to protect them."
  },
  thirdAge: {
    "YEAR 0 / YEAR 967 CE":
      "The war for the conquest of Earth rages on, the stakes higher than ever with the help of newly acquired runes. The humans are able to hold their ground for the first time in thirteen years.",
    "YEAR 15 / YEAR 982 CE":
      "Losses amass among both sides of the seemingly endless war. The humans, after much trial and error, create a gauntlet capable of unleashing the full potential of the runes to suppress the monsters’ magic, bending them to the will of whoever wields the gauntlet. The balance of power begins to shift, the humans continue to gain ground.",
    "YEAR 20 / YEAR 987 CE":
      "Humans, having perfected their magic, discover a previously untouchable advantage with their gauntlets. They can finally fight full force against the monster clans. The Earth is overwhelmed with conflict and destruction, all sides incite unimaginable chaos.",
    "YEAR 40 / YEAR 1007 CE":
      "The Earth is ransacked. The Gods, spurred to action by Vargad, finally intervene. They join forces to end the mayhem on Earth.",
    "YEAR 1054 / YEAR 2021 CE":
      "The millennium that follows is known simply as the “Status Quo.” No clan or known species dares disturb the precarious peace instilled by the Gods and all invaders leave the human world. Only the curious or particularly meddlesome creatures still risk traveling through the gates and between worlds. Humans live sheltered lives, very few still handle the gauntlets past down generation after generation. Over the thousand years that pass, stories of monsters and war fade into folklore and myth. Those who still carry the legacy of the gauntlet dedicate their lives to capturing any monster that lingers for too long on the wrong side of the gate.",
    "YEAR 1055 / YEAR 2022 CE":
      "A new gate appears, this time greater than any other before it.",
  },
};



import { useEffect, useState } from "react";
import Web3 from "web3";

export const switchChain = async () => {
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID as string) }],
  });
};

export function TrimAddress(address: string): string {
  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) {
    return address;
  }
  return `${match[1]}----${match[2]}`;
};

export function triggerNonification(notification:string){
    var sb = document.getElementById("snackbar") as HTMLSpanElement;
    sb.innerHTML = notification;
    sb.className = "show";
    setTimeout(() => {
      sb.className = sb.className.replace("show", "");
      sb.innerHTML=""
    }, 3000);
}

export async function initW3Contract(contractAddress: string, contractAbi: any){
  const w3 = new Web3(Web3.givenProvider)
  const contract = new w3.eth.Contract(contractAbi, contractAddress);
  return contract;
};


export async function getETHprice() {
  let result = await fetch(process.env.REACT_APP_ETH_PRICE_URL as string);

  if (result.ok) {
    let price = await result.text()
    return parseFloat(price);
  }

  return null
}

export function calcFPpriceUSD(ethValue: number, price: number | string) {
  if (typeof price === "string") {
    price = parseFloat(price.replace(',', '.'));
  }
  return `${(ethValue * price).toFixed(4)}`.replace('.', ',')
};

export const toHex = (id:number) => {
  const _hex = Web3.utils.toHex(id);
  return _hex;
};

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};
import React from "react";
import { HOVEREDBUTTON } from "src/utils/types";

const HoveredButton:React.FC<HOVEREDBUTTON> = (props:HOVEREDBUTTON) => {
    return(
      <div
          {...props}
          style={{
            backgroundImage: `url(${props.hover})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            ...props.style
          }}
          className='hovered-button'
          onClick={props.onClick}
>
          <div style={{backgroundImage:`url(${props.bg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            width:"100%",
            height:"100%",
          }} 

            className={`on-hover-button ${props.className}`}
            />
          {props.children}
        </div>
    )
};

export default HoveredButton;
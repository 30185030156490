import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { icons } from "src/assets";
import HoveredButton from "src/components/HoveredButton";
import checked from '../../assets/icons/checked.svg';
import LangModal from "./langModal";
import ReportModal from "./reportModal";

import './styles.css';

const AnnouncementLanguages = {
    english: {
        value: "english",
        display: "english",
    },
    french: {
        value: "french",
        display: "français",
    },
    spanish: {
        value: "spanish",
        display: "español",
    },
    russian: {
        value: "russian",
        display: "русский",
    },
}

// servers
const EUW = "euw";

const SERVERS = [
    EUW,
]

const SupportPage = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const [showReportModal, setShowReportModal] = useState(false);
    const [showLangModal, setShowLangModal] = useState(true);

    const [tab, setTab] = useState<string>('Current messages');


    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = useMemo(() => isMobile ? 1 : 2,[isMobile]);

    const [announcementServer, setAnnouncementServer] = useState(EUW);
    const [announcementLanguage, setAnnouncementLanguage] = useState(AnnouncementLanguages.english);

    // TODO: add proper types
    const [announcementsActive, setAnnouncementsActive] = useState<any[]>([]);
    const [announcementsInactive, setAnnouncementsInactive] = useState<any[]>([]);
    const [announcementsForLanguage, setAnnouncementsForLanguage] = useState<any[]>([]);
    const [currentPosts, setCurrentPosts] = useState<any[]>([]);
    const [indexOfFirstPost, setIndexOfFirstPost] = useState(0);
    const [indexOfLastPost, setIndexOfLastPost] = useState(postsPerPage);

    const executePostsSlice = () => {
        console.log('working the currentPosts')
        const _indexOfLastPost = currentPage * postsPerPage;
        const _indexOfFirstPost = _indexOfLastPost - postsPerPage;
        setIndexOfFirstPost(_indexOfFirstPost);
        setIndexOfLastPost(_indexOfLastPost);
        console.log('changes:', _indexOfFirstPost, _indexOfLastPost, postsPerPage)
        const postsToShow = announcementsForLanguage.slice(indexOfFirstPost, indexOfLastPost)
        console.log('to show:', postsToShow, announcementsForLanguage.slice(0, 2))
        setCurrentPosts(postsToShow);
    }

    useEffect(() => {
        executePostsSlice()
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        executePostsSlice()
    }, [announcementsForLanguage])

    useEffect(() => {
        const lang = localStorage.getItem("serviceLanguage");
        if (lang) {
            handleChangeAnnouncementLanguage(lang);
            setShowLangModal(false);
        }
    }, [])

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if(currentPage < currentPosts.length){
            if (currentPage !== Math.ceil(announcementsForLanguage.length / postsPerPage)) {
                setCurrentPage(currentPage + 1);
            }
        }
        
    };

    const handleChangeAnnouncementLanguage = (langValue: string) => {
        Object.values(AnnouncementLanguages).map((l: any) => {
            if (l.value === langValue) {
                setAnnouncementLanguage(l);
                localStorage.setItem("serviceLanguage", l.value);
            }
        })

    }

    const upperCase = (input: string) => input.toUpperCase();
    const capitalizeFirst = (input: string) => input.charAt(0).toUpperCase() + input.slice(1);

    const loadMessages = () => {
        fetch(
            process.env.REACT_APP_API_BASE_URL + `api/service_announcements/${announcementServer}/`,
            {
              method: "GET",
            }
          ).then((resp) => resp.json()).then((resp) => {
            let active: any[] = [];
            let inactive: any[] = [];
            resp.map((entry) => {
                if (entry.is_active) {
                    active.push(entry)
                } else {
                    inactive.push(entry)
                }
            })
            setAnnouncementsActive(active)
            setAnnouncementsInactive(inactive)
            console.log('backend response:', resp)
          });
    }

    useEffect(() => {
        getAnnouncementsForLanguage();
    }, [announcementsActive, announcementsInactive, announcementLanguage, tab]);

    useEffect(() => {
        loadMessages();
    }, [])

    const getAnnouncementsForLanguage = () => {
        if (!announcementsActive.length || !announcementsInactive.length) return;
        let target: any[] = [];
        // what if there are more tabs in the future?
        if (tab === 'Current messages') {
            target = announcementsActive || []
        } else {
            target = announcementsInactive || []
        }

        let result: any[] = []
        target.map((entry) => {
            if (Object.keys(entry.title).includes(announcementLanguage.value)) {
                result.push(entry);
            }
        })
        setAnnouncementsForLanguage(result);
    }


    return (
        <div>
            
            <div className="assistant-header">
                <h2>Aether TCG SERVICE STATUS</h2>
            </div>
            <div className="assistant-body">
                <div className="assistant-container">
                    <div className="assist-controls">
                        <h4>
                            Cross game issues
                            <br />
                            <span>
                                all times shown in 24-hour format
                            </span>
                        </h4>
                        <section>
                            <select onChange={(e) => setAnnouncementServer(e.target.value)} defaultValue={announcementServer}>
                                {SERVERS.map((serverOption) => <option key={serverOption} value={serverOption}>{upperCase(serverOption)}</option>)}
                            </select>
                            {/* <select onChange={(e) => handleChangeAnnouncementLanguage(e.target.value)} defaultValue={announcementLanguage.value}>
                                {Object.values(AnnouncementLanguages).map((l: any) => <option key={l.value} value={l.value} selected={l.value === announcementLanguage.value}>{capitalizeFirst(l.display)}</option>)}
                            </select> */}
                        </section>
                    </div>

                    <div className="assistant-content">
                        <ul className="tabs">
                            <li className={tab === 'Current messages' ? "active" : ''}
                                onClick={() => setTab('Current messages')}>Current messages</li>
                            <li className={tab === 'Recently closed' ? "active" : ''}
                                onClick={() => setTab('Recently closed')}
                            >Recently closed</li>
                        </ul>
                        <div className="box">
                            <div className="issues">
                            {currentPosts.length > 0 ?
                                <>
                                <section>
                                    <img src={icons.left_arr} onClick={previousPage} style={{
                                        opacity: indexOfFirstPost + 1 === 1 ? 0.5 : 1
                                    }} />
                                    <p>Message {indexOfFirstPost + 1}-{indexOfLastPost > currentPosts.length ? indexOfFirstPost + 1 : indexOfLastPost} from {announcementsForLanguage.length}</p>
                                    <img src={icons.left_arr} onClick={nextPage} />
                                </section>
                                <ul>
                                    {currentPosts.map((i, index) => {
                                        var dateformat = new Date(i.date).toString().split('(');
                                        return (
                                            <li key={i.id}>
                                                <div>
                                                    <strong>{index + 1}</strong>
                                                    <h5><img src={icons.clock} />{dateformat}</h5>
                                                </div>
                                                <h3>{i.title[announcementLanguage.value]}</h3>
                                                <p>{i.text[announcementLanguage.value]}</p>
                                            </li>
                                        )
                                    })}

                                </ul>
                                </>

                            :
                            <section style={{
                                height:'150px',
                                alignItems:'center',
                            }}>
                            <h4>No recent issues or events to report </h4>
                            <img src={checked} alt="" style={{
                                transform:"none"
                            }}/>
                            </section>
                            }
                            </div>
                        </div>
                        <section className="report">
                            <a href="#">Did you find a problem?</a>
                            <HoveredButton bg={require('../../assets/images/backgrounds/report-b.webp')} hover={require('../../assets/images/backgrounds/report-b-hover.webp')} style={{
                                width: '181.37px',
                                height: '70px',
                            }
                            
                        }

                                pressed={require('../../assets/images/backgrounds/report-b-pressed.webp')}
                                
                                onClick={() => setShowReportModal(true)}
                                >
                                <span>Report</span> 
                            </HoveredButton>
                        </section>
                    </div>


                </div>
                <div className="assistant-ftr">
                    <h1>
                        Need an assistance?
                    </h1>
                    <p>Find answers, get troubleshooting advice and more.</p>
                    <a href="https://discord.gg/CVYens9FGJ" target={"_blank"} id='player-support-link'>
                        <HoveredButton bg={require('../../assets/images/backgrounds/player-support-b.webp')}
                            hover={require('../../assets/images/backgrounds/player-support-b-hover.webp')} style={{
                                width: '336px',
                                height: '80px',
                                
                                position:'relative'
                            }}>
                                <span>Visit player support</span>
                        </HoveredButton>
                    </a>
                </div>
            </div>
            {showLangModal &&  <LangModal
                setShowLangModal={setShowLangModal}
                options={AnnouncementLanguages}
                currentValue={announcementLanguage}
                languageCallback={handleChangeAnnouncementLanguage}
            />}
            {showReportModal &&  <ReportModal setShowReportModal={setShowReportModal}/>}
        </div>
    )
}

export default SupportPage;